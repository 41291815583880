/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDoNuQhEYxtyOIyAF7wDcv3TMmpNjD4d7o",
  "appId": "1:1059558565959:web:73aa44f36f99cd3df36962",
  "authDomain": "schooldog-i-dougherty-ga.firebaseapp.com",
  "measurementId": "G-PQC4H3NBWF",
  "messagingSenderId": "1059558565959",
  "project": "schooldog-i-dougherty-ga",
  "projectId": "schooldog-i-dougherty-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-dougherty-ga.appspot.com"
}
